import React, { useState } from 'react';
import classNames from 'classnames/bind';

import { Bell, Verifyok } from '../../icons/';

import { Container, Button, Nav } from 'react-bootstrap';
import { Page } from '../../model';

import style from './index.module.css';
const cx = classNames.bind(style);

export interface NavigationProps {
  pageButton: Page;
  onPageButtonClick: (page: Page) => void;
  orderCount: number;
  onorderCountClick: () => void;
  cuid: string | null;
  verify: boolean;
  setNoticeInfo: (obj: any) => void;
  setNoticeOpenAccount: (accountOpen: boolean) => void;
  setNoticeModelShow: (show: boolean) => void;
}

const Navigation: React.FC<NavigationProps> = ({
  pageButton,
  onPageButtonClick,
  orderCount,
  onorderCountClick: onOrderCountClick,
  cuid,
  verify = false,
  setNoticeInfo,
  setNoticeOpenAccount,
  setNoticeModelShow,
}) => {
  const orderInquireOnClick = (page: Page) => {
    onPageButtonClick(page);
    onOrderCountClick();
  };

  return (
    <div className={cx('px-2', 'nav_bcc', 'py-2')}>
      <div
        className={cx('desktop-hide', 'mt-3', 'd-none')}
        style={{ paddingBottom: '5px' }}
        onClick={() => {
          onPageButtonClick('gameIndex');
        }}
      >
        <Button
          variant="outline-primary"
          className={cx(
            'min-width-55px',
            'w-100',
            'btn-radius',
            pageButton === 'gameIndex' ? 'active' : ''
          )}
        >
          活動首頁
        </Button>
      </div>
      <Nav
        className={cx('justify-content-between', 'desktop-w', 'mobile-hide')}
      >
        <Nav.Item
          className={cx('mobile-hide', 'd-none')}
          onClick={() => {
            onPageButtonClick('gameIndex');
          }}
        >
          <Button
            variant="outline-primary"
            className={cx(
              'nav-rounded',
              'customizedPadding',
              'pageBtn',
              pageButton === 'gameIndex' ? 'active' : ''
            )}
          >
            {' '}
            活動首頁
          </Button>
        </Nav.Item>
        <Nav.Item
          className={cx('mobile-hide')}
          onClick={() => {
            cuid && onPageButtonClick('order');
          }}
        >
          <Button
            disabled={!cuid}
            variant="outline-primary"
            className={cx(
              'btn-radius',
              'nav-rounded',
              'customizedPadding',
              'pageBtn',
              pageButton === 'order' ? 'active' : 'pageBtn',
              !cuid && 'dis-active'
            )}
          >
            <span className={cx('mobile-hide')}>交易</span>
          </Button>
        </Nav.Item>
        <Nav.Item
          className={cx('mobile-hide')}
          onClick={() => {
            cuid && orderInquireOnClick('orderInquire');
          }}
        >
          <Button
            disabled={!cuid}
            variant="outline-primary"
            className={cx(
              'btn-radius',
              'nav-rounded',
              'customizedPadding',
              'position',
              'pageBtn',
              pageButton === 'orderInquire' ? 'active' : '',
              !cuid && 'dis-active'
            )}
          >
            委<span className={cx('mobile-hide')}>回</span>
            {orderCount > 0 && (
              <span className={cx('badge')}>
                <Bell />
              </span>
            )}
          </Button>
        </Nav.Item>
        <Nav.Item
          className={cx('mobile-hide')}
          onClick={() => {
            cuid && orderInquireOnClick('dealInquire');
          }}
        >
          <Button
            disabled={!cuid}
            variant="outline-primary"
            className={cx(
              'btn-radius',
              'nav-rounded',
              'customizedPadding',
              'position',
              'pageBtn',
              pageButton === 'dealInquire' ? 'active' : '',
              !cuid && 'dis-active'
            )}
          >
            成<span className={cx('mobile-hide')}>回</span>
          </Button>
        </Nav.Item>
        <Nav.Item
          className={cx('mobile-hide')}
          onClick={() => {
            cuid && onPageButtonClick('account');
          }}
        >
          <Button
            disabled={!cuid}
            variant="outline-primary"
            className={cx(
              'btn-radius',
              'nav-rounded',
              'customizedPadding',
              'pageBtn',
              pageButton === 'account' ? 'active' : '',
              !cuid && 'dis-active'
            )}
          >
            庫存<span className={cx('mobile-hide')}></span>
          </Button>
        </Nav.Item>
        <Nav.Item className={cx('mobile-hide')}>
          <Button
            variant="outline-primary"
            className={cx(
              'btn-radius',
              'nav-rounded',
              'customizedPadding',
              'position',
              'disabled',
              !cuid && 'dis-active'
            )}
            onClick={() => {
              setNoticeInfo({
                title: '開通完整功能',
                content: '體驗更多更完整服務 \n 歡迎立即開戶',
              });
              setNoticeOpenAccount(true);
              setNoticeModelShow(true);
            }}
          >
            更多<span className={cx('mobile-hide')}></span>
          </Button>
        </Nav.Item>

        <Nav.Item
          className={cx('mobile-hide')}
          onClick={() => {
            cuid && onPageButtonClick('profited');
          }}
        >
          <Button
            disabled={!cuid}
            variant="outline-primary"
            className={cx(
              'btn-radius',
              'nav-rounded',
              'customizedPadding',
              'pageBtn',
              pageButton === 'profited' ? 'active' : '',
              !cuid && 'dis-active'
            )}
          >
            <span className={cx('mobile-hide')}></span>損益
          </Button>
        </Nav.Item>

        <Nav.Item
          className={cx('mobile-hide')}
          onClick={() => {
            cuid && onPageButtonClick('leaderboard');
          }}
        >
          <Button
            disabled={!cuid}
            variant="outline-primary"
            className={cx(
              'btn-radius',
              'nav-rounded',
              'customizedPadding',
              'pageBtn',
              pageButton === 'leaderboard' ? 'active' : '',
              !cuid && 'dis-active'
            )}
          >
            <div className={cx('mobile-hide')}>榜單</div>
            <span className={cx('desktop-hide')}>榜單</span>
          </Button>
        </Nav.Item>
        {/* <Nav.Item
          className={cx('mobile-hide')}
          onClick={() => {
            cuid && onPageButtonClick('selfSelect');
          }}
        >
          <Button
            disabled={!cuid}
            variant="outline-primary"
            className={cx(
              'btn-radius',
              'nav-rounded',
              'customizedPadding',
              'pageBtn',
              pageButton === 'selfSelect' ? 'active' : '',
              !cuid && 'dis-active'
            )}
          >
            自選<span className={cx('mobile-hide')}>清單</span>
          </Button>
        </Nav.Item> */}
        <Nav.Item className={cx('mobile-hide', 'd-none')}>
          <Button
            variant="outline-primary"
            onClick={() => {
              !verify && onPageButtonClick('verify');
            }}
            className={cx(
              'nav-rounded',
              'customizedPadding',
              verify ? 'verifyActive' : 'noneVerify'
            )}
          >
            {verify ? (
              <>
                <Verifyok />
                資格已驗證
              </>
            ) : (
              '認證抽獎資格'
            )}
          </Button>
        </Nav.Item>
      </Nav>
      <div
        className={cx('desktop-hide', 'd-none')}
        style={{ paddingBottom: '5px' }}
        onClick={() => {
          !verify && onPageButtonClick('verify');
        }}
      >
        <Button
          variant="outline-primary"
          className={cx(
            'minWidth-55px',
            'w-100',
            'btn-radius',
            verify ? 'verifyActive' : 'noneVerify'
          )}
        >
          {verify ? (
            <>
              <Verifyok />
              資格已驗證
            </>
          ) : (
            '認證抽獎資格'
          )}
        </Button>
      </div>
      <Nav
        className={cx('justify-content-between', 'desktop-w', 'desktop-hide')}
      >
        <Nav.Item
          className={cx('desktop-hide', 'width-14')}
          onClick={() => {
            cuid && onPageButtonClick('order');
          }}
        >
          <Button
            disabled={!cuid}
            variant="outline-primary"
            className={cx(
              'width-95',
              'btn-padding',
              'btn-radius',
              'pageBtn',
              pageButton === 'order' ? 'active' : '',
              !cuid && 'dis-active'
            )}
          >
            交易
          </Button>
        </Nav.Item>
        <Nav.Item
          className={cx('desktop-hide', 'width-14')}
          onClick={() => {
            cuid && orderInquireOnClick('orderInquire');
          }}
        >
          <Button
            disabled={!cuid}
            variant="outline-primary"
            className={cx(
              'width-95',
              'btn-padding',
              'btn-radius',
              'position',
              'pageBtn',
              pageButton === 'orderInquire' ? 'active' : '',
              !cuid && 'dis-active'
            )}
          >
            委回
            {orderCount > 0 && (
              <span className={cx('badge')}>
                <Bell />
              </span>
            )}
          </Button>
        </Nav.Item>
        <Nav.Item
          className={cx('desktop-hide', 'width-14')}
          onClick={() => {
            cuid && orderInquireOnClick('dealInquire');
          }}
        >
          <Button
            disabled={!cuid}
            variant="outline-primary"
            className={cx(
              'width-95',
              'btn-padding',
              'btn-radius',
              'position',
              'pageBtn',
              pageButton === 'dealInquire' ? 'active' : '',
              !cuid && 'dis-active'
            )}
          >
            成回
            {orderCount > 0 && (
              <span className={cx('badge')}>
                <Bell />
              </span>
            )}
          </Button>
        </Nav.Item>
        <Nav.Item
          className={cx('desktop-hide', 'width-14')}
          onClick={() => {
            cuid && onPageButtonClick('account');
          }}
        >
          <Button
            disabled={!cuid}
            variant="outline-primary"
            className={cx(
              'width-95',
              'btn-padding',
              'btn-radius',
              'pageBtn',
              pageButton === 'account' ? 'active' : '',
              !cuid && 'dis-active'
            )}
          >
            庫存
          </Button>
        </Nav.Item>
        <Nav.Item className={cx('desktop-hide', 'width-14')}>
          <Button
            variant="outline-primary"
            className={cx(
              'width-95',
              'btn-padding',
              'btn-radius',
              'position',
              'disabled',
              !cuid && 'dis-active'
            )}
            onClick={() => {
              setNoticeInfo({
                title: '開通完整功能',
                content: '體驗更多更完整服務 \n 歡迎立即開戶',
              });
              setNoticeOpenAccount(true);
              setNoticeModelShow(true);
            }}
          >
            更多
          </Button>
        </Nav.Item>

        <Nav.Item
          className={cx('desktop-hide', 'width-14')}
          onClick={() => {
            cuid && onPageButtonClick('profited');
          }}
        >
          <Button
            disabled={!cuid}
            variant="outline-primary"
            className={cx(
              'width-95',
              'btn-padding',
              'btn-radius',
              'pageBtn',
              pageButton === 'profited' ? 'active' : '',
              !cuid && 'dis-active'
            )}
          >
            損益
          </Button>
        </Nav.Item>
        <Nav.Item
          className={cx('desktop-hide', 'width-14')}
          onClick={() => {
            cuid && onPageButtonClick('leaderboard');
          }}
        >
          <Button
            disabled={!cuid}
            variant="outline-primary"
            className={cx(
              'width-95',
              'btn-padding',
              'btn-radius',
              'pageBtn',
              pageButton === 'leaderboard' ? 'active' : '',
              !cuid && 'dis-active'
            )}
          >
            榜單
          </Button>
        </Nav.Item>
        {/* <Nav.Item
          className={cx('desktop-hide', 'width-14')}
          onClick={() => {
            cuid && onPageButtonClick('selfSelect');
          }}
        >
          <Button
            disabled={!cuid}
            variant="outline-primary"
            className={cx(
              'width-95',
              'btn-padding',
              'btn-radius',
              'pageBtn',
              pageButton === 'selfSelect' ? 'active' : '',
              !cuid && 'dis-active'
            )}
          >
            自選
          </Button>
        </Nav.Item> */}
      </Nav>
    </div>
  );
};

export default Navigation;
