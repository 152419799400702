import Carousel from 'react-bootstrap/Carousel';
import classNames from 'classnames/bind';

import style from './index.module.css';

const cx = classNames.bind(style);

function CarouselBlock() {
  return (
    <Carousel>
       {/* <Carousel.Item>
        <div
          className={cx('carsouelItem','m-auto')}
          onClick={() => {
            window.location.href = 'https://event.kgi.com.tw/news/event/STGTPCU/index.html';
          }}
        ></div>
      </Carousel.Item>  */}
       {/* <Carousel.Item>
        <div
          className={cx('carsouelItem4','m-auto')}
          onClick={() => {
            window.location.href = 'https://event.kgi.com.tw/news/event/STGFET/index.html';
          }}
        ></div>
      </Carousel.Item>  */}
      {/* <Carousel.Item>
        <div
          className={cx('carsouelItem2','m-auto')}
          onClick={() => {
            window.open('https://eoa.kgi.com.tw/OOA/index.aspx?Source=E240401 ') 
          }}
        ></div>
      </Carousel.Item> */}
      <Carousel.Item>
        <div
          className={cx('carsouelItem3')}
          onClick={() => {
            window.location.href = 'https://www.youtube.com/@KGISIA.channel';
          }}
        ></div>
      </Carousel.Item>
    </Carousel>
  );
}

export default CarouselBlock;
