import { AxiosInstance } from "axios";
import { toast } from "react-toastify";

export interface lineUserLoginPayloadType {
  lineid: string;
  linename: string;
  linemail: string;
  name: string;
  phone: string;
  regsource: string;
}


export interface lineUserLoginResponse {
  code: string;
  mail: string;
  msg: string;
  phone: string;
  uid: number
  regsource: string;

}

export const lineUserLogin = async (
  axios: AxiosInstance,
  payload: lineUserLoginPayloadType
) => {

  const res = await axios.post<lineUserLoginResponse>(
    "api2/User/LineLogin", payload
  );


  if (res.data.code !== '0000') {

  }

  return res.data;
};
