import React, { useEffect, useState } from 'react';
import { Page, loginInfo } from './model';
import './App.css';
import {
  Container,
  Modal,
  Button,
  Nav,
  Navbar,
  NavDropdown,
} from 'react-bootstrap';
import * as Icon from './icons';
import { taskListType } from './api/newApi/getTaskList';
import { slide as Menu } from 'react-burger-menu';
import Burger from './components/Burger';
import { schoolData } from './api/newApi/getSchoolList';
import { Icons, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NoticeModel from './components/NoticeModel';
import PasswordChangeModel from './components/PasswordChangeModel';
import Navigation from './container/Navigation';
import GameIndex from './container/GameIndex';
import Account from './container/Account';
import CampaignQAModel from './components/CampaignQAModel';
import Order from './container/Order';
import OrderInquire from './container/OrderInquire';
import Leaderboard from './container/Leaderboard';
import SelfSelect from './container/SelfSelect';
import Profited from './container/Profited';
import Verify from './container/Verify';
import { FB_CLIENT_ID } from './constants';
import CarsouelBlock from './components/CarsouelBlock';
import { useAsyncRetry, useAsync } from 'react-use';
import api from './api';
import { CampaignType } from './api/newApi/getCampaignQuery';
import dayjs from 'dayjs';

import { connectOrderWebSocket } from './websocket/order';
import {
  connectQuoteWebSocket,
  SessionIdSubject as QuoteSessionIdSubject,
  HeartbeatSubject as QuoteHeartbeatSubject,
} from './websocket/quote';
import { gapi } from 'gapi-script';
import CampaignSubmitModel from './components/CampaignSubmitModel'; //競賽表單
import CampaignRankModel from './components/CampaignRankModel'; //排行榜
import { merge, tap } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { OrderReportMessageSubject, HeartbeatSubject } from './websocket/order';
import classNames from 'classnames/bind';
import style from './index.module.css';
import { GOOGLE_CLIENT_ID } from './constants';
const cx = classNames.bind(style);

function useQuery() {
  return new URLSearchParams(window.location.search);
}

function useConnectOrderSocket(
  cuid: string | null,
  campaignName: string | null,
  wsOrderdisConnect: boolean,
  handleOrderConnect: (state: boolean) => void
) {
  const handleRef = React.useRef(handleOrderConnect);
  handleRef.current = handleOrderConnect;
  React.useEffect(() => {
    const TIMEOUT_DURATION = 15 * 1000;
    if (!cuid || !campaignName) {
      return;
    }
    const socket = connectOrderWebSocket({
      url: `${process.env.REACT_APP_URL}wa/oms2WS`,
      cu_id: cuid,
      campaignName: campaignName,
    });
    handleRef.current?.(true);

    const sub = HeartbeatSubject.pipe(
      tap((message) => {
        // console.log(message)
      }),
      timeout(TIMEOUT_DURATION)
    ).subscribe(
      (value) => {},
      (error) => {
        console.log('error', error);
        socket.close();
        handleRef.current?.(false);
      }
    );

    return () => {
      sub.unsubscribe();
      socket.close();
    };
  }, [cuid, wsOrderdisConnect, campaignName]);
}

function useConnectQuoteSocket(
  cuid: string | null,
  wsQuotedisConnect: boolean,
  handleQuoteConnect: (state: boolean) => void
) {
  const handleRef = React.useRef(handleQuoteConnect);
  handleRef.current = handleQuoteConnect;
  React.useEffect(() => {
    const TIMEOUT_DURATION = 15 * 1000;
    if (!cuid) {
      return;
    }
    const socket = connectQuoteWebSocket({
      url: `${process.env.REACT_APP_URL}wa/ws`,
      // url: `https://marketdata.apex.com.tw/wss`,
      cu_id: cuid,
    });
    handleRef.current?.(true);

    const sub = QuoteHeartbeatSubject.pipe(
      tap((message) => {
        // console.log(message)
      }),
      timeout(TIMEOUT_DURATION)
    ).subscribe(
      (value) => {},
      (error) => {
        socket.close();
        handleRef.current?.(false);
      }
    );

    return () => {
      sub.unsubscribe();
      socket.close();
    };
  }, [cuid, wsQuotedisConnect]);
}
function useVerifyCheck(cuid: string | null) {
  const {
    loading: VerifyCheckLoading,
    value: checkvalue,
    retry: VerifyCheckRetry,
  } = useAsyncRetry(() => api.verifyCheck({ cu_id: cuid }), [cuid]);
  const devicesVerify = checkvalue?.verified || [];
  const phoneIsVerify = React.useMemo(() => {
    const getSMSVerified = devicesVerify.find((i) => i.v_type === '1');
    const isVerify = getSMSVerified?.status || false;
    return isVerify;
  }, [VerifyCheckLoading]);

  return {
    checkvalue,
    phoneIsVerify,
    VerifyCheckRetry,
    VerifyCheckLoading,
  };
}

function useBellCheck(cuid: string | null, t1: string, t2: string) {
  // return
  const { loading, value, retry } = useAsyncRetry(
    () => api.bellCheck({ cu_id: cuid, t1: t1, t2: t2 }),
    [cuid]
  );
  const isBell = React.useMemo(() => {
    const Bell = value?.bell || false;
    return Bell;
  }, [loading]);

  const campaign_active = React.useMemo(() => {
    //console.log("!!!!!!!",value)
    const active =
      value?.err === '1200' ||
      value?.err === '9999' ||
      value?.err === '401' ||
      value === undefined
        ? false
        : true;
    return active;
    // return true;
  }, [loading]);
  const isTimeOut = React.useMemo(() => {
    //console.log("!!!!!!!",value)
    const active = value?.err === '401' ? true : false;
    return active;
  }, [loading]);
  return {
    retry,
    loading,
    isBell,
    campaign_active,
    isTimeOut,
  };
}
function useCampaignInfo(accInfo: loginInfo) {
  const { loading, value, retry } = useAsyncRetry(
    () => api.getCampaignQuery(accInfo),
    []
  );

  const defaultCUID = React.useMemo(() => {
    return value?.cu_id || null;
  }, [value]);

  const campaignActive = React.useMemo(() => {
    return value?.active || true;
  }, [value]);

  function Formatter(data: CampaignType): CampaignType {
    return {
      code: data.code,  // 代碼
      name: data.name,  // 名稱
      t_open: data.t_open,
      t_close: data.t_close,
      s_timeToe_time: data.s_timeToe_time || '',
      d_start: data.d_start, // 競賽開始時間
      d_end: data.d_end,  // 競賽結束時間
      d_start_end: data.d_start_end, 
      init_cash: data.init_cash, // 初始資金
      realtime: data.realtime,
      status: data.status, // 登入狀態
      action: data.code,
      phone: accInfo?.phone || '',
      email: accInfo?.email || '',
      m_type: data.m_type,
      signed: data.signed,  // 是否參加過
      regstatus: data.regstatus, // 是否可以報名
      camp_type: data.camp_type, // 團體 個人 
      reg_start : data.reg_start,  //報名開始時間
      reg_end : data.reg_end,   //報名結束時間
      act_type :data.act_type,  // 校園 企業
      
    };
  }
  const campaignNeedPopupError = React.useMemo(() => {
    return value?.err;
  }, [loading]);

  const Campaign = React.useMemo(() => {
    return value?.campaign ? value?.campaign.map(Formatter) : [];
  }, [loading]);

  return {
    campaignActive,
    campaignNeedPopupError,
    defaultCUID,
    loading,
    Campaign,
    retry,
  };
}

function App() {
  React.useEffect(() => {
    if (
      window.localStorage.getItem('UNAUTHORIZED') &&
      window.localStorage.getItem('UNAUTHORIZED') === 'true'
    ) {
      toast.warn(`登入逾時，請重新登入`, {
        position: 'top-right',
        autoClose: 1300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      window.localStorage.removeItem('UNAUTHORIZED');
    }
  }, []);
  const [checkInfo, setCheckInfo] = useState<boolean>(false);
  const [showMsg, setMsgShow] = useState(false);
  const [modalAction, SetModalAction] = useState(true);
  const [msg, setMsg] = useState('');
  const [w2, setW2] = useState('');
  const [loginInfo, setLoginInfo] = useState<loginInfo>({
    email: null,
    phone: null,
    uid: 0,
  });

  // let userCuid =
  //   sessionStorage.getItem('cuid') && sessionStorage.getItem('cuid') !== '9999'
  //     ? sessionStorage.getItem('cuid')
  //     : null;
  // let sessionUserName = sessionStorage.getItem('userName')
  //   ? sessionStorage.getItem('userName')
  //   : null;
  // let sessionCampaignName = sessionStorage.getItem('code')
  //   ? sessionStorage.getItem('code')
  //   : null;
  // let sessionInvitationCode = sessionStorage.getItem('inv_code')
  //   ? sessionStorage.getItem('inv_code')
  //   : null;

  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [wsOrderdisConnect, setWsOrderConnect] = useState<boolean>(false);
  const [wsQuotedisConnect, setWsQuoteConnect] = useState<boolean>(false);
  const [quoteSessionId, setQuoteSessionId] = useState<string | null>(null);
  const [userName, setUserName] = useState<string>(``);
  const [campaignName, setCampaignName] = useState<string | null>(null);
  const [code, setCode] = useState<string | null>(null);
 
  const [cuid, setCuid] = useState<string | null>(null);
  // const [cuid, setCuid] = useState<string | null>(userCuid ? userCuid : null)
  const [orderCount, setOrderCount] = useState<number>(0); //鈴鐺
  /// if ((window.parent as any).location.href.includes('tradAccount?checkInfo') && checkInfo) {
  const [pageButton, setPageButton] = useState<Page>('verify');
  // (window.parent as any).location.href.includes('tradAccount?verify') && !checkInfo ? "verify" : userCuid ? "order" : "gameIndex")
  const [mtype, setMtype] = useState<string>('');
  const [invitationCode, setInvitationCode] = useState<string | null>(null);
  const [togget, setTogger] = useState<boolean>(false);

  const [t1, setT1] = useState<string>(
    dayjs().format('YYYY/MM/DD HH:mm:ss.000')
  );
  const [t2, setT2] = useState<string>('');
  const [userTaskRefresh, setUserTaskRefresh] = React.useState<Boolean>(false);
  const [userTaskList, setUserTaskList] = React.useState<taskListType[] | []>(
    []
  );
  const [userTaskRefreshTime, setUserTaskRefreshTime] =
    React.useState<string>('');
  const [passwordModelShow, setPasswordModelShow] = useState(false);
  const [hasShownModal, setHasShownModal] = useState(false); // 新增狀態來彈窗是否已經顯示過
  const [campaignLoaded, setCampaignLoaded] = useState(false);
  const [forgetPasswordUserData, setForgetPasswordUserData] = useState({});
  const [passwordChangeType, setPasswordChangeType] = useState('change'); //type forget 忘記密碼 expire 密碼到期 change 變更密碼
  const [campaignSubmitModelShow, setCampaignSubmitModelShow] = useState(false); // 競賽表單
  const [campaignQAModelShow, setCampaignQAModelShow] = useState(false);
  const [campaignQAType, setCampaignQAType] = useState(0);
  const [noticeModelShow, setNoticeModelShow] = useState(false);
  const [campaignRankModel, setCampaignRankModel] = useState(false); // 競賽排名
  const query = useQuery();
  // const decodedCode = query.get('code'); 
  const [decodedCode, setDecodedCode] = useState<string | null>(null);


  const [noticeInfo, setNoticeInfo] = useState<{
    title: string;
    content: string;
  }>({ title: '', content: '' });
  const [noticeOpenAccount, setNoticeOpenAccount] = useState(false);
  const [schoolList, setSchoolList] = useState([]);
  const [announcementModalShow, setAnnouncementModalShow] = useState(false);
  const [announcement, setAnnouncement] = useState({ title: '', content: '' });
  const [orderBackgroundColor, setOrderBackgroundColor] = useState<string>('');
  //只出現一次 提示參加活動
  const [firstCampaignShow, setFirstCampaignShow] = useState(false);
  const {
    campaignActive,
    loading: CampaignQueryLoading,
    Campaign: CampaignQuery,
    defaultCUID: defauleCuid,
    retry: getCampaignRetry,
    campaignNeedPopupError,
  } = useCampaignInfo(loginInfo);
  useEffect(() => {
    setOrderBackgroundColor('');
  }, [pageButton]);
  // 載入 Facebook SDK
  useEffect(() => {
    // 載入 Facebook SDK
    (function (d, s, id) {
      var js: any,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode && fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }, []);

  function decodeBase64(base64: string): string {
    try {
      const binaryString = atob(base64);
      const utf8Decoder = new TextDecoder('utf-8');
      const binaryArray = Uint8Array.from(binaryString, (c) => c.charCodeAt(0));
      const decodedString = utf8Decoder.decode(binaryArray);
      if (/[\u0000-\u001F\u007F-\u009F]/.test(decodedString)) {
        console.error('Decoded string contains invalid characters:', decodedString);
        return '';
      }
      return decodedString.includes('%') ? decodeURIComponent(decodedString) : decodedString;
    } catch (e) {
      console.error('Failed to decode Base64 string:', e);
      return '';
    }
  }
  

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let encodedCode = localStorage.getItem('code');
    
    if (encodedCode) {
      try {
        console.log('Original encoded code:', encodedCode);
        encodedCode = decodeURIComponent(encodedCode);
        console.log('Decoded URL component:', encodedCode);
  
        const decoded = decodeBase64(encodedCode);
        console.log('Decoded Base64 string:', decoded);
        
        if (decoded) {
          setDecodedCode(decoded);
        } else {
          console.error('Decoded code is invalid.');
        }
      } catch (error) {
        console.error('Error decoding Base64:', error);
      }
    }
  }, []);
  
  function getCampaignByCode(code: string, campaigns: CampaignType[]): CampaignType | undefined {
    return campaigns.find(campaign => campaign.code === code);
  }
  
  useEffect(() => {
    if (CampaignQuery.length > 0) {
      setCampaignLoaded(true);
    }
  }, [CampaignQuery]);
  
  useEffect(() => {
    if (!decodedCode) {
      console.error('decodedCode is invalid or missing.');
      setFirstCampaignShow(false);
      return;
    }
    const currentCampaign = getCampaignByCode(decodedCode, CampaignQuery);
    if (!currentCampaign) {
      console.error(`No campaign found for decoded code: ${decodedCode}`);
      setFirstCampaignShow(false);
      return;
    }
  
    if (isLogin && campaignLoaded && currentCampaign) {
      if (currentCampaign.regstatus === false) {
        setFirstCampaignShow(false);
        setHasShownModal(false);
        localStorage.removeItem('hasShownModal');
        return; 
      }
      if (currentCampaign.signed !== true) {
        setFirstCampaignShow(true);
        setCampaignName(currentCampaign.name);
  
        if (!hasShownModal) {
          setHasShownModal(true);
          localStorage.setItem('hasShownModal', 'true');
        }
      } else {
        setFirstCampaignShow(false);
      }
    } else {
      setFirstCampaignShow(false);
    }
  }, [isLogin, campaignLoaded, CampaignQuery, decodedCode]);
  
  useEffect(() => {
    function start() {
      gapi.client.init({
        // apiKey: GOOGLE_API_KEY,
        client_id: GOOGLE_CLIENT_ID,
        // scope: GOOGLE_SCOPE,
      });
    }
    gapi.load('client:auth2', start);
  }, []);
  useEffect(() => {
    if (!campaignSubmitModelShow) {
      getCampaignRetry();
    }
  }, [loginInfo, campaignSubmitModelShow]);
  // React.useEffect(() => {
  //   if (!loginInfo.email || !loginInfo.phone) {
  //     window.location.href = `${process.env.REACT_APP_URL}#/home`;
  //   }
  // }, []);
  useEffect(() => {
    if (window.location.hash.includes('resetPw')) {
      let urlParamsEmail = new URLSearchParams(
        window.location.hash.split('?')[1]
      );
      // window.history.pushState(
      //   '',
      //   document.title,
      //   window.location.pathname + window.location.search
      // );

      setForgetPasswordUserData({
        email: urlParamsEmail.get('email'),
        code: urlParamsEmail.get('code'),
      });
      setPasswordChangeType('change');
      setPasswordModelShow(true);
    }
  }, []);
  useEffect(() => {
    if (campaignNeedPopupError === '1054') {
      setPasswordChangeType('expire');
      setPasswordModelShow(true);
      //超過90天，換密碼
    } 
    // else if (campaignNeedPopupError === '1055') {
    //   setNoticeInfo({
    //     title: '開戶申請',
    //     content: '凱基證券邀請您線上開戶，實現您的投資計畫',
    //   });
    //   setNoticeOpenAccount(true);
    //   setNoticeModelShow(true);
    //   //十倍登入次數，給開戶邀請
    // } else if (campaignNeedPopupError === '1056') {
    //   setPasswordChangeType('expire');
    //   setPasswordModelShow(true);
    //   setNoticeInfo({
    //     title: '開戶申請',
    //     content: '凱基證券邀請您線上開戶，實現您的投資計畫',
    //   });

    //   //超過90天，換密碼  十倍登入次數，給開戶邀請
    // }
  }, [campaignNeedPopupError]);

  const getSchool = async () => {
    const res = await api.getSchoolList();
    if (res.err === '0000') {
      console.log('getSchoolList', res);
      setSchoolList(res.data as any);
    }
  };
  useEffect(() => {
    getSchool();
  }, []);

  //取得公告的api
  const getAnnouncement = async () => {
    try {
      const res = await fetch('/api2/Warrant/Announce', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ key: 'value' }) // 根據 API 的需求來填寫 body 的內容
      });
      // 檢查伺服器回應的狀態碼
      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }
      // 嘗試解析 JSON，直接使用 res.json() 進行解析
      const data = await res.json();
      console.log('Announcement Data:', data);
  
      if (data.err === '0000' && data.data?.length > 0) {
        const { title, content, stats } = data.data[0];
        if (stats === 'Y') {
          setAnnouncement({ title, content });
          setAnnouncementModalShow(true);
        }
      }
    } catch (error) {
      console.error('Error fetching announcement:', error);
    }
  };

  useEffect(() => {
    getAnnouncement();
  }, []);

  const getUserTask = async () => {
    return; //暫時關閉 有活動將這條刪掉即可
    // if(!cuid && !defauleCuid) return
    // const response = await api.getTaskList({cu_id:cuid?cuid:defauleCuid})
    // if(response.err==='0000'){
    //   setUserTaskList(response.user_task[0].taskList || [])
    //   const refreshTime = dayjs(response.user_task[0].sysDate).format("YYYY/MM/DD HH:mm:ss")
    //   setUserTaskRefreshTime(refreshTime)
    //   console.log(userTaskList)
    // } else {
    //   //若err不會為0000 基本上就是為'TaskMsg' 也就是非任務期間 將其塞到更新時間的state中 用以辨識是否為任務期間
    //   setUserTaskRefreshTime(response.err);
    //   (window.parent as any).resizeIframe();
    // }
  };

  React.useEffect(() => {
    if (userTaskRefreshTime !== 'TaskMsg') {
      getUserTask();
    }
  }, [cuid, defauleCuid, userTaskRefresh, pageButton]);

  const { checkvalue, phoneIsVerify, VerifyCheckRetry, VerifyCheckLoading } =
    useVerifyCheck(defauleCuid);

  useConnectOrderSocket(cuid, campaignName, wsOrderdisConnect, (state) => {
    setWsOrderConnect(state);
  });
  useConnectQuoteSocket(cuid, wsQuotedisConnect, (state) => {
    setWsQuoteConnect(state);
  });
  const { retry, isBell, campaign_active, isTimeOut } = useBellCheck(
    cuid,
    t1,
    t2
  );
  const [TimeOut, setTimeOut] = useState<boolean>(false);
  React.useEffect(() => {
    const subscriber = QuoteSessionIdSubject.subscribe(
      ({ from, sessionId }) => {
        if (sessionId) {
          setQuoteSessionId(sessionId);
        }
      }
    );
    return () => {
      subscriber.unsubscribe();
    };
  }, [wsQuotedisConnect]);

  // React.useEffect(() => {
  //   if (userCuid) {
  //     setTogger(!togget);
  //   }
  // }, []);

  const showMsgBox = (msg: string) => {
    if (pageButton !== 'gameIndex') {
      return;
    }
    SetModalAction(true);
    setMsg(msg);
    setMsgShow(true);
  };
  //查詢簡訊驗證狀態跳出提醒視窗
  React.useEffect(() => {
    if (checkvalue && checkvalue.w2) {
      setW2(checkvalue.w2);
    }
  }, [checkvalue]);
  React.useEffect(() => {
    if (w2 === '') {
      return;
    }
    if (w2 && !phoneIsVerify) {
      showMsgBox(w2);
    }
  }, [w2]);

  //小鈴鐺每三秒輪詢,查詢委託單狀態
  React.useEffect(() => {
    const firstDate = dayjs().format('YYYY/MM/DD HH:mm:ss.000');
    setT1(firstDate);
  }, []);
  // React.useEffect(() => {
  //   setTimeOut(isTimeOut)
  //   if (TimeOut === true && campaign_active === false) {
  //     (window.parent as any).reactLogout(
  //       `登入閒置已超過20分鐘，請重新登入!!`
  //     );
  //   }
  // }, [isTimeOut, TimeOut])

  React.useEffect(() => {
    const everySecond = 3;
    if (isBell) {
      setOrderCount(orderCount + 1);
    }
    const creatInt = setInterval(() => {
      if (TimeOut === true && campaign_active === false) {
        return;
      }
      const t1 = dayjs()
        .subtract(everySecond, 'second')
        .format('YYYY/MM/DD HH:mm:ss.000');
      const t2 = dayjs().format('YYYY/MM/DD HH:mm:ss.000');
      setT1(t1);
      setT2(t2);
      if (campaign_active) {
        retry();
      }
      setTogger(!togget);
    }, everySecond * 1000);
    return () => {
      clearInterval(creatInt);
    };
  }, [togget]);

  const handleGameCampaign = (
    cuid: string,
    campaignName: string,
    code: string,
    userName: string,
    m_type: string
  ) => {
    setUserName(userName);
    setCuid(cuid);
    setWsOrderConnect(false);
    setCode(code);
    setCampaignName(campaignName);
    setPageButton('order');
    setMtype(m_type);
  };

  const renderPage = (function () {
    const AccountVerify = (
      <Verify
        phoneIsVerify={phoneIsVerify}
        w1={checkvalue?.w1 || ''}
        m2={checkvalue?.m2 || ''}
        setPageButton={(page) => {
          setPageButton(page);
          setFirstCampaignShow(true);
        }}
        defauleCuid={defauleCuid}
        showMsg={(msg) => showMsgBox(msg)}
        VerifyCheckRetry={() => VerifyCheckRetry()}
        VerifyCheckLoading={VerifyCheckLoading}
        setUserIslogin={(login) => setIsLogin(login)}
        setLoginInfo={(info) => setLoginInfo(info)}
        setPasswordModelShow={(state) => setPasswordModelShow(state)}
        setPasswordChangeType={(type) => setPasswordChangeType(type)}
      />
    );
    const GameIndexPage = (
      <GameIndex
        CampaignQueryLoading={CampaignQueryLoading}
        setUserTaskRefresh={() => {
          setUserTaskRefresh(!userTaskRefresh);
        }}
        refreshTime={userTaskRefreshTime}
        userTaskList={userTaskList}
        CampaignQuery={CampaignQuery}
        cuid={cuid ? cuid : ''}
        campaignName={campaignName}
        handleGameCampaign={(cuid, campaignName, code, userName, m_type) => {
          handleGameCampaign(cuid, campaignName, code, userName, m_type);
        }}
        campaignActive={campaignActive}
        setPageButton={setPageButton}
        setCampaignShow={(e) => setCampaignSubmitModelShow(e)} 
      />
    );
    
    switch (pageButton) {
      case 'gameIndex':
        return GameIndexPage;
      case 'account':
        if (!isLogin) {
          return AccountVerify;
        }
        if (!cuid) {
          return GameIndexPage;
        }
        return (
          <Account
            cuid={cuid}
            campaign_active={campaign_active}
            code={code}
            campaignName={campaignName}
            userName={userName}
            sessionId={quoteSessionId}
            mtype={mtype}
            handleOninvitationCode={(code) => {
              setInvitationCode(code);
            }}
            loginInfo={loginInfo}
            refreshTime={userTaskRefreshTime}
            invitationCode={invitationCode}
            continueBell={() => {
              setTogger(!togget);
            }}
            setPageButton={(e) => {
              setPageButton(e);
            }}
          />
        );
      case 'order':
        console.log('campaign!!', campaign_active);
        if (!isLogin) {
          return AccountVerify;
        }
        if (!cuid) {
          return GameIndexPage;
        }
        return (
          <Order
            cuid={cuid}
            campaign_active={campaign_active}
            campaignName={campaignName}
            sessionId={quoteSessionId}
            mtype={mtype}
            userName={userName}
            handleOninvitationCode={(code) => {
              setInvitationCode(code);
            }}
            invitationCode={invitationCode}
            continueBell={() => {
              setTogger(!togget);
            }}
            setOrderBackgroundColor={(e) => {
              setOrderBackgroundColor(e);
            }}
            loginInfo={loginInfo}
            setNoticeInfo={setNoticeInfo}
            setNoticeOpenAccount={setNoticeOpenAccount}
            setNoticeModelShow={setNoticeModelShow}
          />
        );
      case 'selfSelect':
        if (!isLogin) {
          return AccountVerify;
        }
        if (!cuid) {
          return AccountVerify;
        }
        return (
          <SelfSelect
            cuid={cuid}
            campaign_active={campaign_active}
            campaignName={campaignName}
            sessionId={quoteSessionId}
            mtype={mtype}
            loginInfo={loginInfo}
            handleOninvitationCode={(code) => {
              setInvitationCode(code);
            }}
            invitationCode={invitationCode}
            continueBell={() => {
              setTogger(!togget);
            }}
            setPageButton={(e) => {
              setPageButton(e);
            }}
          />
        );
      case 'profited':
        if (!isLogin) {
          return AccountVerify;
        }
        if (!cuid) {
          return GameIndexPage;
        }
        return <Profited cuid={cuid} campaignName={campaignName} />;
      case 'orderInquire':
        if (!isLogin) {
          return AccountVerify;
        }
        if (!cuid) {
          return GameIndexPage;
        }
        return (
          <OrderInquire
            campaign_active={campaign_active}
            cuid={cuid}
            loginInfo={loginInfo}
            code={code as string}
            campaignName={campaignName}
            orderCount={orderCount}
            continueBell={() => {
              setTogger(!togget);
            }}
            pageButton={pageButton}
          />
        );
      case 'dealInquire':
        if (!isLogin) {
          return AccountVerify;
        }
        if (!cuid) {
          return GameIndexPage;
        }
        return (
          <OrderInquire
            campaign_active={campaign_active}
            cuid={cuid}
            loginInfo={loginInfo}
            code={code as string}
            campaignName={campaignName}
            orderCount={orderCount}
            continueBell={() => {
              setTogger(!togget);
            }}
            pageButton={pageButton}
          />
        );
      case 'leaderboard':
        if (!isLogin) {
          return AccountVerify;
        }
        if (!cuid) {
          return GameIndexPage;
        }
        return (
          <Leaderboard
            cuid={cuid}
            campaignName={campaignName}
            userName={userName}
            code={code || ''}
          />
        );
        case 'campaignSubmitModel':
      return (
        <CampaignSubmitModel
          isOpen={true}
          handleClose={() => setPageButton('gameIndex')}
          schoolList={schoolList}
          cuid={cuid ? `${cuid}` : `${defauleCuid}`}
          loginInfo={loginInfo}
          game={CampaignQuery}
        />
      );
      case 'verify':
        return AccountVerify;
      default:
        return AccountVerify;
    }
  })();
  const FUNC_TYPE_MAP: Record<string, string> = {
    10: '委託成功',
    11: '委託成功',
    20: '刪單成功',
    21: '刪單失敗',
    30: '改單成功',
    31: '改單失敗',
    90: '委託成交',
  };
  React.useEffect(() => {
    const subscriber = merge(OrderReportMessageSubject).subscribe((message) => {
      message.FUNC !== 90 &&
        toast.info(
          <>
            <h5>{FUNC_TYPE_MAP[message.FUNC]}</h5>
            <div className="toastContain">
              標的：{message.SYM}
              <br />
              價格：{message.P1}
              <br />
              數量：{message.QTY}
            </div>
          </>,
          {
            position: 'top-right',
            autoClose: 1300,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );

      message.FUNC === 90 &&
        toast.success(
          <>
            <h5>{FUNC_TYPE_MAP[message.FUNC]}</h5>
            <div className="toastContain">
              標的：{message.SYM}
              <br />
              價格：{message.P1}
              <br />
              數量：{message.QTY}
            </div>
          </>,
          {
            position: 'top-right',
            autoClose: 1300,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
    });
    return () => {
      subscriber.unsubscribe();
    };
  }, [orderCount]);

  return (
    <div className={cx('App', 'd-flex', 'flex-column')} id="outer-container">
      {isLogin ? (
        <div>
          {pageButton === 'gameIndex' ? (
            <Burger
              setCampaignShow={(e) => setCampaignSubmitModelShow(e)}
              setCampaignQAType={(e) => setCampaignQAType(e)}
              setCampaignQAShow={(e) => setCampaignQAModelShow(e)}
              setPasswordChangeType={(e) => {
                setPasswordChangeType(e);
              }}
              openModel={() => {
                setPasswordModelShow(true);
              }}
              campaignActive={campaignActive}
              regstatus={CampaignQuery[0]?.regstatus || false} 
              signed={CampaignQuery[0]?.signed || false} 
              campaignStartDate={CampaignQuery[0]?.d_start || '2022-01-01T00:00:00'} 
              campaignEndDate={CampaignQuery[0]?.d_end || '2099-12-31T00:00:00'} 
              setCampaignRankModel={setCampaignRankModel}
            />
          ) : (
            <div
              className={cx('bm-burger-button')}
              onClick={() => setPageButton('gameIndex')}
            >
              <Icon.ArrowLeft width={36} height={36} color="#000000" />
            </div>
          )}

          <div className="position-relative p-0">
            <>
              <div
                className={cx(
                  'navBarShowName',
                  'd-flex',
                  'justify-content-center',
                  'align-items-center',
                  'px-2'
                )}
              >
                {' '}
                {!!(pageButton !== 'gameIndex' && code !== 'RealTime')
                  ? `股神大賽 ${campaignName}`
                  : '模擬交易'}
              </div>
              {pageButton !== 'verify' && (
                <>
                  <div className="padding_for_carsouelBlock"></div>
                  <CarsouelBlock />
                </>
              )}
              {pageButton !== 'gameIndex' && (
                <Navigation
                  pageButton={pageButton}
                  onPageButtonClick={(page) => {
                    setPageButton(page);
                  }}
                  orderCount={orderCount}
                  onorderCountClick={() => {
                    setOrderCount(0);
                  }}
                  cuid={cuid}
                  verify={phoneIsVerify}
                  setNoticeInfo={setNoticeInfo}
                  setNoticeOpenAccount={setNoticeOpenAccount}
                  setNoticeModelShow={setNoticeModelShow}
                />
              )}
            </>
          </div>
        </div>
      ) : (
        ''
      )}

      {/* page-Container */}
      <div
        className={cx(
          'page-background',
          'flex-grow-1',
          pageButton === 'order' && orderBackgroundColor === 'B'
            ? 'backGround-Buy'
            : pageButton === 'order' && orderBackgroundColor === 'S'
            ? 'backGround-Sell'
            : pageButton === 'order'
            ? 'backGround-Normal'
            : ''
        )}
      >
        {pageButton === 'verify' ? (
          <div className={cx('position-relative')}>{renderPage}</div>
        ) : (
          <Container className={cx('position-relative')}>
            {renderPage}
          </Container>
        )}
      </div>

      {/* page-foot */}
      <div className="footToast">
        <ToastContainer limit={2} rtl={false} newestOnTop={false} />
      </div>
      {/* <div
        className="ver"
        onClick={() => {
          setPasswordChangeType('expire');
          setPasswordModelShow(true);
        }}
      >
        {process.env.REACT_APP_VER}
      </div> */}

      <PasswordChangeModel
        isOpen={passwordModelShow}
        handleClose={() => setPasswordModelShow(false)}
        type={passwordChangeType}
        cuid={cuid ? `${cuid}` : `${defauleCuid}`}
        email={loginInfo.email as string}
        setPasswordChangeType={(e) => {
          setPasswordChangeType(e);
        }}
        openModel={() => {
          setPasswordModelShow(true);
        }}
        noticeInfo={noticeInfo}
        showNoticeModel={() => {
          setNoticeModelShow(true);
        }}
        setNoticeInfo={(e) => {
          setNoticeInfo(e);
        }}
        forgetPasswordUserData={forgetPasswordUserData}
      />
      <NoticeModel
        isOpen={noticeModelShow}
        handleClose={() => {
          setNoticeModelShow(false);
          setNoticeInfo({ title: '', content: '' });
          setNoticeOpenAccount(false);
        }}
        title={noticeInfo.title}
        content={noticeInfo.content}
        openAccount={noticeOpenAccount}
      />
     <CampaignSubmitModel
  isOpen={campaignSubmitModelShow}
  handleClose={() => {
    setCampaignSubmitModelShow(false);
  }}
  schoolList={schoolList}
  cuid={cuid ? `${cuid}` : `${defauleCuid}`}
  loginInfo={loginInfo}
  game={CampaignQuery}
/>
<CampaignRankModel
  isOpen={campaignRankModel}
  handleClose={() => {
    setCampaignRankModel(false);
    setPageButton('gameIndex');
  }}
  cuid={cuid ? `${cuid}` : `${defauleCuid}`}
  loginInfo={loginInfo}
  game={CampaignQuery}
  handleGameCampaign={(cuid, campaignName, code, userName, m_type) => {
    handleGameCampaign(cuid, campaignName, code, userName, m_type);
  }}
/>
<CampaignQAModel
  isOpen={campaignQAModelShow}
  handleClose={() => setCampaignQAModelShow(false)}
  type={campaignQAType as 1 | 2 | 3}
  campaigns={CampaignQuery}  
  loginInfo={{
    phone: loginInfo.phone ?? '',  
    email: loginInfo.email ?? '',  
    uid: loginInfo.uid || 0, 
  }}
/>
       {/* 公告 Modal */}
      <Modal
        show={announcementModalShow}
        onHide={() => setAnnouncementModalShow(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton style={{ width: '100%',  }}>
          <div style={{ width: '100%',textAlign: 'center', paddingLeft: '40px' }}>{announcement.title}</div>
        </Modal.Header>        
        <Modal.Body>
          {announcement.content}
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-center w-100">
            <Button
              className="modal-rounded px-4 col-4 mx-1"
              onClick={() => setAnnouncementModalShow(false)}
            >
              確認
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
          {/* 登入後 Modal */}
      <Modal
        show={firstCampaignShow}
        onHide={() => setFirstCampaignShow(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
        「{campaignName}」
          <br />
          請點選下方報名
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-center w-100">
            {/* <Button
              className="modal-rounded px-4 col-4 mx-1"
              onClick={() => setFirstCampaignShow(false)}
            >
              不再提醒
            </Button> */}
            <Button
              className="modal-rounded px-4 col-4 mx-1"
              onClick={() => {
                setFirstCampaignShow(false);
                setCampaignSubmitModelShow(true);
              }}
            >
              確認
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default App;
