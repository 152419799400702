import { AxiosInstance } from "axios";

export interface googleUserJoinPayloadType {
  goid: string;
  goname: string;
  goemail: string;
  phone: string;
  name: string;
  regsource : string;
}
export interface googleUserJoinType {
  code: number;
  mail: string;
  msg: string;
  phone: string;
  uid: number;
  regsource : string;
}

export const googleUserJoin = async (axios: AxiosInstance, payload: googleUserJoinPayloadType) => {
  // const { uid } = (window.parent as any).reactGetLoginInfo();
  // const uidstring = `?uid=${uid}`;
  const { data } = await axios.post<googleUserJoinType>(
    `${process.env.REACT_APP_URL}api/google_join.php`, payload
  );

  console.log(data)
  if (!data) {

  }
  return data;
};
