import { AxiosInstance } from "axios";
import { toast } from "react-toastify";

export interface userJoinPayloadType {
  email: string;
  phone: string;
  password: string;
  name: string;
  regsource: string | null;
}
export interface userJoinType {
  code: number;
  mail: string;
  msg: string;
  phone: string;
  uid: number;
  regsource: string | null;
}

export const userJoin = async (axios: AxiosInstance, payload: userJoinPayloadType) => {
  // const { uid } = (window.parent as any).reactGetLoginInfo();
  // const uidstring = `?uid=${uid}`;
  const { data } = await axios.post<userJoinType>(
    `${process.env.REACT_APP_URL}api/user_join.php`, payload
  );

  console.log(data)
  if (data.code === 0) {
    // toast.success(`註冊成功，請重新登入`, {
    //   position: "top-right",
    //   autoClose: 1300,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    // });
  } else {
    toast.error(`註冊失敗，原因：${data.msg}`, {
      position: "top-right",
      autoClose: 1300,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }
  return data;
};
