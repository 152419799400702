import { AxiosInstance } from "axios";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { loginInfo } from "../../model";
export interface CampaignQueryResponse {
  err: string;
  cu_id: string;
  campaign: CampaignType[];
  active?: boolean;
}

export interface CampaignType {
  code: string;
  name: string;
  t_open: string;
  t_close: string;
  s_timeToe_time?: string;
  d_start: string;
  d_end: string;
  d_start_end?: string;
  init_cash: number;
  realtime: string;
  status: boolean;
  m_type: string;
  action?: string;
  phone?: string;
  email?: string;
  signed: boolean;
  regstatus?:boolean; 
  camp_type : number;
  reg_start?: string;  
  reg_end?: string; 
  act_type? : string;   
  note? : string;
}

function Formatter(data: CampaignType): CampaignType {
  return {
    code: data.code,
    name: data.name,
    t_open: data.t_open,
    t_close: data.t_close,
    s_timeToe_time:

      data.t_open && data.t_close
        ? data.t_open.substring(0, 2) +
        ":" +
        data.t_open.substring(2, 4) +
        "~" +
        data.t_close.substring(0, 2) +
        ":" +
        data.t_close.substring(2, 4)
        : "--",
    d_start: data.d_start,
    d_end: data.d_end,
    d_start_end:
      data.code === 'RealTime' ? '--' :
        dayjs(data.d_start).format("YYYY.MM.DD") +
        "\n~" +
        dayjs(data.d_end).format("YYYY.MM.DD"),
    init_cash: data.init_cash,
    realtime: data.realtime ? "即時" : "回播",
    status: data.status || false,
    m_type: data.m_type,
    signed: data.signed,
    regstatus : data.regstatus,
    camp_type : data.camp_type,
    reg_start : data.reg_start,
      reg_end : data.reg_end,
      act_type : data.act_type,
      note : data.note,
  };
}

export const getCampaignQuery = async (axios: AxiosInstance, accInfo: loginInfo) => {

  // const accInfo = {
  //   email: 'apextest@mail.apex.com.tw',
  //   phone: '0912345678',
  //   uid: 709
  // }
  // const accInfo = (window.parent as any).reactGetLoginInfo();
  const apiResponseAllowArray = ['0000', '1054', '1055', '1056']
  console.log('accInfo', accInfo)
  if (accInfo.phone === "" || accInfo.email === "") {
    // (window.parent as any).openLoginModal()
    return
  }
  if (!accInfo.phone || !accInfo.email) return
  const requestData = JSON.stringify({
    phone: accInfo.phone,
    email: accInfo.email,
  });
  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
  };
  const response = await axios.post<CampaignQueryResponse>(
    "api2/Warrant/CampaignQuery",
    requestData,
    { headers }
  );
  if (!response.data) {
    return { err: "9999", cu_id: "", campaign: [] };
  }
  if (!apiResponseAllowArray.includes(response.data.err)) {
    toast.warn(`活動清單${":(" + response.data.err + ")"}`, {
      position: "top-right",
      autoClose: 1300,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

  }
  return response.data.campaign
    ? {
      err: response.data.err,
      cu_id: response.data.cu_id,
      active: response.data.active,
      campaign: response.data.campaign.map(Formatter),
    }
    : response.data;
};
