import React, { useState } from 'react';

import {
  Container,
  Badge,
  Spinner,
  Button,
  OverlayTrigger,
  Popover,
  Modal,
} from 'react-bootstrap';
import { Table } from '../../components/DataTable';
import dayjs from 'dayjs';
import { Page, loginInfo } from '../../model';
import { Column } from 'react-table';

import { useAsyncRetry } from 'react-use';
import api from '../../api';
import { asset, invType } from '../../api/newApi/getOnhand';

import AleartModel from '../../components/AleartModel';

import { toast } from 'react-toastify';

import { Bell } from '../../icons/';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import classNames from 'classnames/bind';
import style from './index.module.css';

const cx = classNames.bind(style);

export interface AccountProps {
  cuid: string;
  code: string | null;
  campaignName: string | null;
  userName: string;
  refreshTime: string;
  sessionId: string | null;
  mtype: string;
  handleOninvitationCode: (code: string | null) => void;
  invitationCode: string | null;
  campaign_active: boolean;
  continueBell: () => void;
  loginInfo: loginInfo;
  setPageButton: (page: Page) => void;
}
function useOnhandInfo(cuid: string) {
  const { loading, value, retry } = useAsyncRetry(
    () => api.getOnhandQuery({ cu_id: cuid }),
    []
  );
  const asset = React.useMemo(() => {
    const assetInfo = [
      {
        csh: value?.csh || 0,
        stk: value?.stk || 0,
        nat: value?.nat || 0,
        pf: value?.pf || 0,
        roi: value?.roi || 0,
      },
    ];
    return assetInfo;
  }, [loading]);
  const inv = React.useMemo(() => {
    return value?.inv ? value?.inv : [];
  }, [loading]);

  const queryTime = value?.QueryTime ? value?.QueryTime : '';
  return {
    retry,
    loading,
    asset,
    inv,
    queryTime,
  };
}

// function useInvitationQuery(cuid: string | null) {
//   const { loading, value } = useAsyncRetry(
//     () => api.invitationQuery({ cu_id: cuid }),
//     [cuid]
//   );

//   const invitation = React.useMemo(() => {
//     if (!cuid) {
//       return '請選擇活動';
//     }
//     return value?.inv_code ? value?.inv_code : null;
//   }, [loading]);

//   return {
//     loading,
//     invitation,
//   };
// }

interface LastDayTipProps {
  sym: string;
  day: string;
  name: string;
  todayDate: string;
}

const LastDayTip: React.FC<LastDayTipProps> = ({
  day,
  name,
  todayDate,
  sym,
}) => {
  function TipText() {
    if (Number(day) <= 10 && Number(day) > 1) {
      return (
        <>
          今日(<span className={cx('tip')}>{todayDate}</span>
          )是您在模擬交易的庫存商品<span className={cx('tip')}>{name}</span>
          距到期日只剩<span className={cx('tip')}>{day}天</span>
          ，權證是有到期日的，適合短線操作，不宜長期持有。
        </>
      );
    }
    if (Number(day) === 1) {
      return (
        <>
          今日({todayDate})是您在模擬交易的庫存商品
          <span className={cx('tip')}>{name}</span>
          <span style={{ color: 'red' }}>最後交易日</span>，請把握最後交易機會。
        </>
      );
    }
    if (Number(day) <= 0) {
      return (
        <>
          您在模擬交易的庫存商品<span className={cx('tip')}>{name}</span>
          <span style={{ color: 'red' }}>已到期</span>，以
          <span className={cx('tip')}>{todayDate}</span>到期結算價幫您計算損益。
        </>
      );
    }
  }
  return (
    <OverlayTrigger
      trigger="click"
      key={sym}
      placement={'top'}
      rootClose
      overlay={
        <Popover id={`popover-positioned-${sym}`}>
          <Popover.Header as="h3">{`提醒您!`}</Popover.Header>
          <Popover.Body>{TipText()}</Popover.Body>
        </Popover>
      }
    >
      <span className={cx('Bell')}>
        <Bell />
      </span>
    </OverlayTrigger>
  );
};
const Account: React.FC<AccountProps> = ({
  cuid,
  code,
  refreshTime,
  campaignName,
  userName,
  sessionId,
  mtype,
  handleOninvitationCode,
  invitationCode,
  campaign_active,
  continueBell,
  loginInfo,
  setPageButton,
}) => {
  // const { loading: valueyLoading, invitation } = useInvitationQuery(cuid);

  // const shareUrl = `${process.env.REACT_APP_URL}#/home?inviteCode=${
  //   invitation ? invitation : ''
  // }`;
  // const title = '歡迎一起參加權證模擬交易抽大獎,我的邀請碼:' + `${invitation}`;

  const [show, setShow] = useState(false);
  const [showinviteModel, setShowinviteModel] = useState(false);

  const [istop6K, setIstop6K] = useState<boolean | null>(null);

  const [symCnName, setSymCnName] = useState<string>('');
  const [sym, setSym] = useState<string>('');
  const [bS1, setBS1] = useState<'B' | 'S' | null>(null);
  const [p1, setP1] = useState<number | null>(null);
  const [qty, setQty] = useState<number>(1);
  const [tif, setTif] = useState<'R' | 'F' | 'I' | null>(null);
  const [ort, setOrt] = useState<'1' | '2' | null>(null);
  const [togget, setTogger] = useState<boolean>(false);
  const [teamQuery, setTeamQuery] = useState<any[]>([]);
  const [teamName, setTeamName] = useState<string>('');

  const [t1, setT1] = useState<string>(
    dayjs().format('YYYY/MM/DD HH:mm:ss.000')
  );
  const [t2, setT2] = useState<string>('');

  // 母股漲停
  const [t_up_stop, setT_up_stop] = useState<number | null>(null);
  // 母股跌停
  const [t_down_stop, setT_down_stop] = useState<number | null>(null);
  //母股現價
  const [target_money, setTarget_money] = useState<string>('');

  const [openAlertModel, setOpenAlertModel] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<'income' | 'reset' | null>(null);

  const getTeamQuery = () => {
    api.teamQuery({ cu_id: cuid }).then((e) => {
      if (e?.err === '0000') {
        setTeamQuery(e.data as any);
        setTeamName(e.team_name as any);
      }
    });
  };

  // React.useEffect(() => {
  //   if (code === 'KGI02') {
  //     getTeamQuery();
  //   }
  // }, []);

  // React.useEffect(() => {
  //   handleOninvitationCode(invitation);
  //   // (window.parent as any).resizeIframe()
  // }, [invitation]);
  const handleClose = () => {
    setShow(false);
    setSymCnName('');
    setSym('');
    setBS1(null);
    setP1(null);
    setQty(1);
    setTif(null);
    setOrt(null);
    // (window.parent as any).showBanner(1);
    setTimeout(() => {
      // (window.parent as any).scrollTo({
      //   top: 0,
      //   behavior: 'smooth',
      //   scrollBehavior: 'smooth'
      // });
    }, 100);
  };
  const handleOpenAlert = (type: 'income' | 'reset' | null) => {
    setAlertType(type);
    setOpenAlertModel(true);
  };
  const handleAlert = () => {
    if (alertType === 'income') {
      api.deposit({ cu_id: cuid }).then(() => retry());
    }
    if (alertType === 'reset') {
      cuid && api.accountReset({ cu_id: cuid }).then(() => retry());
    }

    setAlertType(null);
    setOpenAlertModel(false);
  };
  // const handleOrder = (row: any, BS: 'B' | 'S') => {
  //   const t1 = dayjs().subtract(3, 'second').format('YYYY/MM/DD HH:mm:ss.000');
  //   const t2 = dayjs().format('YYYY/MM/DD HH:mm:ss.000');
  //   setT1(t1);
  //   setT2(t2);
  //   if (!campaign_active) {
  //     api.bellCheck({ cu_id: cuid, t1: t1, t2: t2 }).then((e) => {
  //       if (e?.err === '1200') {
  //         toast.warn('非系統交易時間！', {
  //           position: 'top-right',
  //           autoClose: false,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //         });
  //         // (window.parent as any).scrollTo({
  //         //   top: 11,
  //         //   behavior: 'smooth',
  //         // });
  //         return;
  //       } else {
  //         continueBell();
  //         setShow(true);
  //         setSymCnName(row.name);
  //         setSym(row.sym);
  //         setP1(row.pc);
  //         setOrt('2');
  //         setTif('R');
  //         setBS1(BS);
  //         setT_up_stop(row.t_up_stop);
  //         setT_down_stop(row.t_down_stop);
  //         setTarget_money(row.t_money);
  //         // (window.parent as any).showBanner(0);
  //         // (window.parent as any).scrollTo({
  //         //   top: 11,
  //         //   behavior: 'smooth',
  //         // });
  //       }
  //     });
  //   } else {
  //     setShow(true);
  //     setSymCnName(row.name);
  //     setSym(row.sym);
  //     setP1(row.pc);
  //     setOrt('2');
  //     setTif('R');
  //     setBS1(BS);
  //     setT_up_stop(row.t_up_stop);
  //     setT_down_stop(row.t_down_stop);
  //     setTarget_money(row.t_money);
  //     // (window.parent as any).showBanner(0);
  //     // (window.parent as any).scrollTo({
  //     //   top: 11,
  //     //   behavior: 'smooth',
  //     // });
  //   }
  // };

  // const handlePrice = (price: number) => {
  //   setP1(price);
  //   setOrt('2');
  // };

  // const handlePriceType = (type: '1' | '2' | null) => {
  //   setOrt(type);
  //   if (type === '1') {
  //     setP1(0);
  //   }
  // };

  // const handleOrderPlace = () => {
  //   if (!bS1 || !tif || !ort || p1 == null) {
  //     toast.warn('請填妥下單條件', {
  //       position: 'top-right',
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //     return;
  //   }
  //   if (ort === '2' && (!p1 || Number(p1) <= 0 || p1 <= 0)) {
  //     toast.warn('限價單請輸入價格', {
  //       position: 'top-right',
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //     return;
  //   }

  //   api
  //     .OrderPlace({
  //       cu_id: cuid,
  //       src: 'WEB',
  //       sym: sym,
  //       bS1: bS1,
  //       p1: p1,
  //       qty: qty,
  //       tif: tif,
  //       ort: ort,
  //       icc: invitationCode === null ? '1' : '0',
  //       dt: false,
  //       uid: loginInfo.uid as number,
  //     })
  //     .then((e) => {
  //       handleClose();
  //     });
  // };
  React.useEffect(() => {
    if (istop6K !== null && invitationCode === null) setShowinviteModel(true);
  }, [istop6K, invitationCode]);

  const {
    loading: OnhandLoading,
    inv: invDate,
    asset: assetDate,
    retry,
    queryTime,
  } = useOnhandInfo(cuid);
  const todayDate = dayjs().format('YYYY/MM/DD');
  const numberFormatter = (value: string, digits?: number) => {
    //根據凱基電商規定 成交均價 <50 顯示兩位小數，<500 顯示一位小數，>=500 顯示整數
    if (digits !== 0 && !digits) {
      if (+value < 50) {
        digits = 2;
      } else if (+value < 500) {
        digits = 1;
      } else {
        digits = 0;
      }
    }
    return (+value).toLocaleString(undefined, {
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    });
  };

  React.useEffect(() => {
    const everySecond = 60;
    const creatInt = setInterval(() => {
      if (campaign_active) {
        retry();
      }
      setTogger(!togget);
    }, everySecond * 1000);
    return () => {
      clearInterval(creatInt);
    };
  }, [togget]);

  const user_status_columns = React.useMemo<Column<asset>[]>(
    () => [
      {
        Header: '現金資產',
        accessor: 'csh',
        sticky: 'left',
        disableSortBy: true,
        Cell: (cellInfo) => (
          <>
            {
              <div>
                {numberFormatter(cellInfo.cell.row.original.csh.toString(), 0)}
              </div>
            }
          </>
        ),
      },
      {
        Header: '帳面收入',
        accessor: 'stk',
        disableSortBy: true,
        Cell: (cellInfo) => (
          <>
            {
              <div>
                {numberFormatter(
                  Math.floor(cellInfo.cell.row.original.stk).toString(),
                  0
                )}
              </div>
            }
          </>
        ),
      },
      {
        Header: '淨資產',
        accessor: 'nat',
        disableSortBy: true,
        Cell: (cellInfo) => (
          <>
            {
              <div>
                {numberFormatter(
                  Math.floor(cellInfo.cell.row.original.nat).toString(),
                  0
                )}
              </div>
            }
          </>
        ),
      },
      {
        Header: '已實現損益',
        accessor: 'pf',
        disableSortBy: true,
        Cell: (cellInfo) => (
          <>
            {
              <div
                className={cx(
                  cellInfo.cell.row.original.pf > 0
                    ? 'red'
                    : cellInfo.cell.row.original.pf < 0
                    ? 'green'
                    : ''
                )}
              >
                {numberFormatter(
                  Math.round(cellInfo.cell.row.original.pf).toString(),
                  0
                )}
              </div>
            }
          </>
        ),
      },
      {
        Header: '報酬率(%)',
        accessor: 'roi',
        disableSortBy: true,
        Cell: (cellInfo) => (
          <>
            {
              <div
                className={cx(
                  cellInfo.cell.row.original.roi > 0
                    ? 'red'
                    : cellInfo.cell.row.original.roi < 0
                    ? 'green'
                    : ''
                )}
              >
                {numberFormatter(cellInfo.cell.row.original.roi.toString(), 2)}
              </div>
            }
          </>
        ),
      },
    ],
    []
  );

  const inv_columns = React.useMemo<Column<invType>[]>(
    () => [
      {
        Header: (
          <>
            <div className={cx('tableHeadAction')}>選項</div>
          </>
        ),
        accessor: 'action',
        sticky: 'left',
        disableSortBy: true,
        Cell: (cellInfo) => (
          <>
            {
              <div
                className={cx('btn-group', 'tableHeadAction')}
                style={{ flexWrap: 'wrap', margin: 'auto' }}
              >
                {/* <button
                  className={cx(
                    'btn',
                    'red',
                    cellInfo.cell.row.original.s_stats === 0
                      ? 'disabled_btn'
                      : ''
                  )}
                  style={{ marginBottom: '5px' }}
                  disabled={cellInfo.cell.row.original.s_stats === 0}
                  onClick={() => {
                    if (cellInfo.cell.row.original.s_stats === 0) return;
                    window.sessionStorage.setItem(
                      'ORDER_OBJECT',
                      JSON.stringify({
                        stock: cellInfo.cell.row.original.sym,
                        BS: 'B',
                      })
                    );
                    setPageButton('order');
                    // handleOrder(cellInfo.cell.row.original, 'B');
                  }}
                >
                  買進
                </button> */}
                <button
                  className={cx(
                    'btn',
                    'green',
                    cellInfo.cell.row.original.s_stats === 0
                      ? 'disabled_btn'
                      : ''
                  )}
                  disabled={cellInfo.cell.row.original.s_stats === 0}
                  onClick={() => {
                    if (cellInfo.cell.row.original.s_stats === 0) return;
                    // handleOrder(cellInfo.cell.row.original, 'S');
                    window.sessionStorage.setItem(
                      'ORDER_OBJECT',
                      JSON.stringify({
                        stock: cellInfo.cell.row.original.sym,
                        BS: 'S',
                      })
                    );
                    setPageButton('order');
                  }}
                >
                  下單
                </button>
              </div>
            }
          </>
        ),
      },
      {
        Header: (
          <>
            <div className={cx('tableHeadwrap')}>商品</div>
          </>
        ),
        accessor: 'name',
        disableSortBy: true,
        Cell: (cellInfo) => (
          <>
            {
              <div className={cx('tableHeadwrap', 'text-center', 'w-100')}>
                {(cellInfo.cell.row.original.name &&
                  cellInfo.cell.row.original.name) ||
                  '--'}
              </div>
            }
          </>
        ),
      },
      {
        Header: (
          <>
            <div style={{ width: '95px', margin: 'auto' }}>商品代號</div>
          </>
        ),
        accessor: 'sym',
        disableSortBy: true,
        Cell: (cellInfo) => (
          <>
            {
              <div style={{ width: '95px', margin: 'auto' }}>
                {(cellInfo.cell.row.original.sym &&
                  cellInfo.cell.row.original.sym) ||
                  '--'}
              </div>
            }
          </>
        ),
      },
      {
        Header: (
          <>
            <div style={{ width: '75px', margin: 'auto' }}>股數</div>
          </>
        ),
        accessor: 'qty',
        disableSortBy: true,
        Cell: (cellInfo) => (
          <>
            {
              <div style={{ width: '75px', margin: 'auto' }}>
                {(
                  cellInfo.cell.row.original.qty &&
                  Number(cellInfo.cell.row.original.qty)
                ).toLocaleString('en-US') || '--'}
              </div>
            }
          </>
        ),
      },
      {
        Header: (
          <>
            <div className={cx('tableHeadwrap')}>成交均價</div>
          </>
        ),
        accessor: 'pa',
        disableSortBy: true,
        Cell: (cellInfo) => (
          <>
            {
              <>
                <div>
                  {numberFormatter(cellInfo.cell.row.original.pa.toString())}
                </div>
              </>
            }
          </>
        ),
      },
      {
        Header: (
          <>
            <div className={cx('tableHeadwrap')}>
              投資成本/
              <br />
              帳面收入<span className={cx('unit')}>(元)</span>
            </div>
          </>
        ),
        accessor: 'bc',
        disableSortBy: true,
        Cell: (cellInfo) => (
          <>
            {(cellInfo.cell.row.original.bc &&
              numberFormatter(cellInfo.cell.row.original.bc.toString(), 0)) ||
              '--'}
            <br />
            {(cellInfo.cell.row.original.mV2 &&
              numberFormatter(
                Math.floor(cellInfo.cell.row.original.mV2).toString(),
                0
              )) ||
              '--'}
          </>
        ),
      },
      {
        Header: (
          <>
            <div className={cx('tableHeadwrap')}>損益</div>
          </>
        ),
        accessor: 'pf',
        disableSortBy: true,
        Cell: (cellInfo) => (
          <>
            {
              <div
                style={{ width: '100%',}}
                className={cx(
                  'tableHeadwrap',
                  cellInfo.cell.row.original.pf > 0
                    ? 'red'
                    : cellInfo.cell.row.original.pf < 0
                    ? 'green'
                    : ''
                )}
              >
                {(cellInfo.cell.row.original.pf &&
                  numberFormatter(
                    Math.round(cellInfo.cell.row.original.pf).toString(),
                    0
                  )) ||
                  '--'}
              </div>
            }
          </>
        ),
      },
      {
        Header: (
          <>
            <div style={{ width: '80px', margin: 'auto' }}>
              損益率<span className={cx('unit')}>(%)</span>
            </div>
          </>
        ),
        accessor: 'roi',
        disableSortBy: true,
        Cell: (cellInfo) => (
          <>
            {
              <div
                className={cx(
                  cellInfo.cell.row.original.roi > 0
                    ? 'red'
                    : cellInfo.cell.row.original.roi < 0
                    ? 'green'
                    : ''
                )}
                style={{ width: '80px', margin: 'auto' }}
              >
                {' '}
                {(cellInfo.cell.row.original.roi &&
                  numberFormatter(
                    cellInfo.cell.row.original.roi.toString(),
                    2
                  )) ||
                  '--'}
              </div>
            }
          </>
        ),
      },
    ],
    [campaign_active]
  );
  // React.useEffect(() => {
  //   handleOninvitationCode(invitation);
  //   // (window.parent as any).resizeIframe();
  // }, [invitation, valueyLoading, invDate]);
  return (
    <div className={cx('page-tradAccount')}>
      <Container className={cx('container-left')} fluid>
        <div
          className={cx(
            'text-color',
            'container-left',
            'marginy-3px',
            'justify-content-between'
          )}
          style={{ display: 'flex', paddingBottom: '5px' }}
        >
          {/* 30檔api打開時這邊也要打開 */}
          {code !== 'RealTime' ? (
            ''
          ) : (
            <>
              <div className={cx('col-5', 'text-center')}>
                <Button
                  className={cx('asset-btn', 'income', 'w-100')}
                  onClick={() => {
                    handleOpenAlert('income');
                  }}
                >
                  入金
                </Button>
                <div className={cx('w-100', 'text-center')}>每天點我拿20萬</div>
              </div>
              <div className={cx('col-5', 'text-center')}>
                <Button
                  className={cx('asset-btn', 'reset', 'w-100')}
                  onClick={() => {
                    handleOpenAlert('reset');
                  }}
                >
                  重置帳戶
                </Button>
                <div className={cx('w-100', 'text-center')}>清除模擬資料</div>
              </div>
            </>
          )}
        </div>

        <Table
          columns={user_status_columns}
          data={assetDate}
          pagination={false}
          loading={OnhandLoading && !invDate}
        />

        {code === 'KGI02' && (
          <>
            <div className={cx('text-color', 'container-left', 'invWarp')}>
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                className="text-color bi bi-play"
                viewBox="0 0 16 16"
              >
                <path d="M10.804 8 5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z" />
              </svg> */}
              {/* 隊伍名稱:<span className="ms-1">{teamName}</span> */}
            </div>
            <div className={cx('d-flex', 'container-left', 'text-color')}>
              <div className={cx('text-color')}>
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  fill="currentColor"
                  className="text-color bi bi-play"
                  viewBox="0 0 16 16"
                >
                  <path d="M10.804 8 5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z" />
                </svg> */}
                {/* 隊伍成員: */}
              </div>
              <div className="ms-1 mb-3">
                {Array.isArray(teamQuery) &&
                  teamQuery.map((v: any, i: number) => {
                    return (
                      <div className={cx('mb-1')}>
                        {v}
                        {i < teamQuery.length - 1 && (
                          <span className={cx('ms-1')}>、</span>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          </>
        )}

        <div className={cx('text-color', 'container-left', 'mt-2')}>
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="currentColor"
            className={cx('text-color', 'bi', 'bi-play')}
            viewBox="0 0 16 16"
          >
            <path d="M10.804 8 5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z" />
          </svg> */}
          庫存清單 <Badge className={cx('badge-box')}>{invDate.length}筆</Badge>
        </div>
        <div className={cx('updateTime')}>更新時間：{queryTime || ''}</div>

        <div className={cx('tableHead-minHeight')}>
          <Table
            columns={inv_columns}
            loading={OnhandLoading && !invDate}
            data={invDate}
            pagination={true}
            renderPage="inv_columns"
          />
        </div>

        <div className={cx('list-decimal', 'text-secondary', 'small', 'mb-3')}>
          Tip: <br />
          <br />
          <div>[資產總計]</div>
          <div className="d-flex">
            <div className="flex-grow-0">◎</div>
            <div className="flex-grow-1">
              帳面收入（含手續費、稅） ：加總每筆庫存〔(現價× 庫存張數 × 1000 )
              - (現價× 庫存張數 × 1000 × 手續費率) - (現價× 庫存張數 × 1000 ×
              交易稅率 ) 〕
            </div>
          </div>
          <div className="d-flex">
            <div className="flex-grow-0">◎</div>
            <div className="flex-grow-1">
              淨資產： 現金資產 + 帳面收入 （*盤後會與「排行榜的投組淨值」一致）
            </div>
          </div>
          <div className="d-flex">
            <div className="flex-grow-0">◎</div>
            <div className="flex-grow-1">損益：淨資產-初始資金(含入金)</div>
          </div>
          <div className="d-flex">
            <div className="flex-grow-0">◎</div>
            <div className="flex-grow-1">
              報酬率(%)：盤中「現價」計算；盤後會與「排行榜的報酬率」一致
            </div>
          </div>
          <br />
          <div>[庫存]</div>
          <div className="d-flex">
            <div className="flex-grow-0">◎</div>
            <div className="flex-grow-1">
              成交均價（不含手續費、稅）：加總每筆(買入價或賣出價 × 庫存張數) /
              庫存股數
            </div>
          </div>
          <div className="d-flex">
            <div className="flex-grow-0">◎</div>
            <div className="flex-grow-1">投資成本（包含稅/費）：</div>
          </div>
          <div className="ps-3">
            先買後賣：加總每筆(買入價 × 庫存張數× 1000 ) + ( 買入價 × 庫存張數 ×
            1000 × 手續費率)
          </div>
          <div className="ps-3">
            先賣後買：加總每筆(賣出價 × 庫存張數× 1000 )－( 賣出價 × 庫存張數 ×
            1000 × 手續費率) - ( 賣出價 × 庫存張數 × 1000 × 交易稅率 )
          </div>
          <div className="d-flex">
            <div className="flex-grow-0">◎</div>
            <div className="flex-grow-1">
              帳面收入(元)：(現價× 庫存張數 × 1000 ) - (現價× 庫存張數 × 1000 ×
              手續費率) - (現價× 庫存張數 × 1000 × 交易稅率 )
            </div>
          </div>
          <div className="d-flex">
            <div className="flex-grow-0">◎</div>
            <div className="flex-grow-1">損益</div>
          </div>
          <div className="ps-3">先買後賣：帳面收入(元) – 投資成本</div>
          <div className="ps-3">先賣後買：投資成本 - 帳面收入(元)</div>
          <div className="d-flex">
            <div className="flex-grow-0">◎</div>
            <div className="flex-grow-1">
              損益率(%)：損益/投資成本（包含稅/費）
            </div>
          </div>
          <div className="d-flex">
            <div className="flex-grow-0">◎</div>
            <div className="flex-grow-1">現先賣當沖交易：</div>
          </div>
          <div className="ps-3">
            現先賣均價：加總每筆(賣出價 × 賣出張數+賣出手續費 +當沖賣出交易稅) /
            庫存股數
          </div>
          <div className="d-flex">
            <div className="flex-grow-0">◎</div>
            <div className="flex-grow-1">手續費買賣皆收：</div>
          </div>
          <div className="ps-3">
            以買入價及賣出價的0.1425%計算，未滿20元按20元計收。
          </div>
          <div className="d-flex">
            <div className="flex-grow-0">◎</div>
            <div className="flex-grow-1">交易稅賣出時計收：</div>
          </div>
          <div className="ps-3">
            台股現股交易以賣出價的0.3%計算；現股當沖交易以賣出價的0.15%計算。
          </div>
          <div className="ps-3">ETF現股/當沖交易以賣出價的0.1%計算。</div>
          <div className="d-flex">
            <div className="flex-grow-0">◎</div>
            <div className="flex-grow-1">除權息成本調整：</div>
          </div>
          <div className="ps-3">除權息時，以0元取得調整成本</div>
        </div>

        <AleartModel
          isOpen={openAlertModel}
          handleClose={() => setOpenAlertModel(false)}
          handleCheck={() => {
            handleAlert();
          }}
          type={alertType}
        />
      </Container>
    </div>
  );
};

export default Account;
